<template>
  <Toast />
  <div class="card">
    <div class="p-grid p-fluid dashboard">
      <div class="p-col-12 p-md-2 p-xl-2">
        <Card>
          <template #header>
            <div style="padding: 50px">
              <img src="../../public/assets/layout/images/sespa/catraca.png" />
            </div>
          </template>
          <template #title> CATRACA 1 </template>
          <template #subtitle>Catraca na recepção. </template>
          <template #footer>
            <Button
              label="LIBERAR"
              class="p-button-raised p-button-success"
              @click="liberar(1)"
            />
          </template>
        </Card>
      </div>
      <div class="p-col-12 p-md-2 p-xl-2">
        <Card>
          <template #header>
            <div style="padding: 50px">
              <img src="../../public/assets/layout/images/sespa/catraca.png" />
            </div>
          </template>
          <template #title> CATRACA 2 </template>
          <template #subtitle>Catraca na recepção. </template>
          <template #footer>
            <Button
              label="LIBERAR"
              class="p-button-raised p-button-success"
              @click="liberar(2)"
            />
          </template>
        </Card>
      </div>
      <div class="p-col-12 p-md-2 p-xl-2">
        <Card>
          <template #header>
            <div style="padding: 50px">
              <img src="../../public/assets/layout/images/sespa/catraca.png" />
            </div>
          </template>
          <template #title> CATRACA 3 </template>
          <template #subtitle>Catraca na recepção. </template>
          <template #footer>
            <Button
              label="LIBERAR"
              class="p-button-raised p-button-success"
              @click="liberar(3)"
            />
          </template>
        </Card>
      </div>
      <div class="p-col-12 p-md-2 p-xl-2">
        <Card>
          <template #header>
            <div style="padding: 50px">
              <img src="../../public/assets/layout/images/sespa/catraca.png" />
            </div>
          </template>
          <template #title> CATRACA 4 </template>
          <template #subtitle>Catraca na recepção. </template>
          <template #footer>
            <Button
              label="LIBERAR"
              class="p-button-raised p-button-success"
              @click="liberar(4)"
            />
          </template>
        </Card>
      </div>
      <div class="p-col-12 p-md-2 p-xl-2">
        <Card>
          <template #header>
            <div style="padding: 50px">
              <img src="../../public/assets/layout/images/sespa/catraca.png" />
            </div>
          </template>
          <template #title> CATRACA 5 </template>
          <template #subtitle>Catraca próximo ao refeitório. </template>
          <template #footer>
            <Button
              label="LIBERAR"
              class="p-button-raised p-button-success"
              @click="liberar(5)"
            />
          </template>
        </Card>
      </div>
      <div class="p-col-12 p-md-2 p-xl-2">
        <Card>
          <template #header>
            <div style="padding: 50px">
              <img src="../../public/assets/layout/images/sespa/catraca.png" />
            </div>
          </template>
          <template #title> CATRACA 6 </template>
          <template #subtitle>Catraca no estacionamento. </template>
          <template #footer>
            <Button
              label="LIBERAR"
              class="p-button-raised p-button-success"
              @click="liberar(6)"
            />
          </template>
        </Card>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="p-grid p-fluid dashboard">
      <div class="p-col-12 p-md-12 p-xl-12">
        <div class="p-text-center">
          <h1 class="relogio">
            {{ timestamp }}
          </h1>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="p-grid p-fluid dashboard">
      <div class="p-col-12 p-md-3 p-xl-3">
        <Card>
          <template #header> </template>
          <template #title> CANCELA 1 </template>
          <template #subtitle>Não disponível. </template>
          <template #footer>
            <Button label="LIBERAR" class="p-button-raised p-button-success" />
          </template>
        </Card>
      </div>
      <div class="p-col-12 p-md-3 p-xl-3">
        <Card>
          <template #header> </template>
          <template #title> CANCELA 2 </template>
          <template #subtitle>Não disponivel. </template>
          <template #footer>
            <Button label="LIBERAR" class="p-button-raised p-button-success" />
          </template>
        </Card>
      </div>
      <div class="p-col-12 p-md-6 p-xl-6">
        <div class="p-d-flex p-ai-center p-jc-center ctisDiv">
          <img
            src="assets/layout/images/sespa.png"
            alt="SESPA"
            width="500"
            class="ctis"
            style="margin-top: 35px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "@/service/Auth/AuthService";
import CatracaService from "@/service/Equipamentos/CatracaService";

export default {
  components: {},
  data() {
    return {
      timestamp: "",
      resposta: "",
    };
  },
  authService: null,
  catracaService: null,
  created() {
    this.authService = new AuthService();
    this.catracaService = new CatracaService();
    clearInterval(this.getNow());
  },
  mounted() {
    setInterval(this.getNow, 1000);
  },
  methods: {
    getNow: function () {
      const today = new Date();
      let h = today.getHours();
      let m = today.getMinutes();
      let s = today.getSeconds();

      h = this.checkTime(h);
      m = this.checkTime(m);
      s = this.checkTime(s);

      this.timestamp = h + ":" + m + ":" + s;

      if (h <= 12) {
        this.timestamp = this.timestamp + " AM";
      } else {
        this.timestamp = this.timestamp + " PM";
      }
    },

    checkTime(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },

    liberar(numero) {
      this.catracaService.liberar(numero).then((data) => {
        this.resposta = data;
        //  console.log(this.resposta);
        this.$toast.add({
          severity: "success",
          summary: "Mensagem!",
          detail: `${this.resposta}`,
          life: 3000,
        });
      });
    },
    travar() {
      this.catracaService.travar().then((data) => {
        this.resposta = data;
        console.log(this.resposta);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ctisDiv {
  width: 100%;
}
.ctis {
  width: 70%;
}
.liberar {
  cursor: pointer;
  font-size: 5px;
}
.relogio {
  font-size: 80px;
}
.liberar:hover {
  color: #fff;
}

p {
  line-height: 1.5;
  margin: 0;
}
</style>