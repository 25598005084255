import axios from "axios";

//const API_URL = 'https://172.32.1.16/script/';
const API_URL = 'https://catraca.saude.pa.gov.br/script/';

export default class CatracaService {

    liberar(numero) {
        return axios({
            url: API_URL + 'funcao/liberar_entrada' + numero + '.php',
        }).then(res => res.data);
    }

    /* LISTAR TODOS */
    listar() {
        return axios({
            method: 'get',
            url: this.api_url + 'catraca',
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + this.token(),
            },
        }).then(res => res.data);
    }
    /* CADASTRAR */
    criar(cracha) {
        return axios({
            method: 'post',
            url: this.api_url + 'cracha',
            withCredentials: true,
            data: cracha,
            headers: {
                'Authorization': 'Bearer ' + this.token(),
            }
        });
    }
    /* REMOVER */
    remover(id) {
        return axios({
            method: 'delete',
            url: this.api_url + 'cracha/' + id,
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + this.token(),
            }
        });

    }
    /* ATUALIZAR */
    atualizar(id, cracha) {
        return axios({
            method: 'put',
            url: this.api_url + 'cracha/' + id,
            withCredentials: true,
            data: cracha,
            headers: {
                'Authorization': 'Bearer ' + this.token(),
            }
        });
    }
    /* ATUALIZAR STATUS*/
    atualizarStatus(id, cracha) {
        return axios({
            method: 'put',
            url: this.api_url + 'cracha/ativo/' + id,
            withCredentials: true,
            data: cracha,
            headers: {
                'Authorization': 'Bearer ' + this.token(),
            }
        });
    }




}
